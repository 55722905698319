<template>
  <b-row class="px-2">
    <b-col cols="12">
      <div class="notice-container">
        <b-table
          ref="refProductsTable"
          class="position-relative"
          :items="testList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="해당 데이터가 없습니다"
          :sort-desc.sync="isSortDirDesc"
        >
           <template #cell(id)="data">
            {{ data.item.totalPrice - data.item.discount }}
          </template>
           <template #cell(amount)="data">
            {{ data.item.amount + (data.item.unit == "EA" ? "개" : "g") }}
          </template>
        </b-table>
        <!-- {{testList}} -->
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { 
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BTooltip,
} from 'bootstrap-vue';

import useShowModal from './useShowModal'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTooltip
  },
  setup(props, context) {
    const SCANNER_STORE_MODULE_NAME = 'scanner'


    const { 
      refProductsTable,
      tableColumns,
      sortBy,
      isSortDirDesc,
      testList,
      fetchModalList,
      products
    } = useShowModal(SCANNER_STORE_MODULE_NAME, props, context);

    return { 
      refProductsTable,
      tableColumns,
      sortBy,
      isSortDirDesc,
      testList,
      fetchModalList,
      products,
    };

    
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>