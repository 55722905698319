<template>
  <b-card no-body class="p-2">
      <b-row class="px-2 pb-1 mb-1 border-bottom">
        <b-col cols="12">
          <b-card
            header="등록 정보"
            bg-variant="transparent"
            border-variant="secondary"
            text-variant="primary"
            class="mb-1"
            >
            <b-card-text>
              <b-row>
                <b-col md="3" class="border-right">
                  <b-form-group
                    label="이름"
                    label-for="scanner_name"
                  >
                    <b-form-input
                      id="scanner_name"
                      placeholder="등록된 이름"
                      v-model="scannerData.name"
                      disabled
                    />
                  </b-form-group>
                  <b-form-group
                    label="카트 번호"
                    label-for="scanner_cart"
                  >
                    <b-form-input 
                    id="scanner_cart"
                    placeholder="등록된 카트번호"
                    v-model="scannerData.cart"
                    disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="5" class="border-right">
                  <b-form-group
                    label="휴대폰 번호"
                    label-for="scanner_phone"
                  >
                    <b-form-input 
                    id="scanner_phone"
                    placeholder="등록된 휴대폰 번호"
                    v-model="scannerData.phone"
                    disabled
                    />
                  </b-form-group>
                  <b-form-group
                    label="주소"
                    label-for="scanner_address"
                  >
                    <b-form-input 
                    id="scanner_address"
                    placeholder="주소"
                    v-model="scannerData.roadAddress"
                    disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="배송 날짜"
                    label-for="scanner_date"
                  >
                    <b-form-input
                    id="scanner_date"
                    placeholder="배송 날짜"
                    v-model="scannerData.delivaryDate"
                    disabled
                    />
                  </b-form-group>
                  <b-form-group
                    label="상세 주소"
                    label-for="scanner_address2"
                  >
                  <b-form-input 
                    id="scanner_address2"
                    placeholder="상세 주소"
                    v-model="scannerData.addAddress"
                    class="mt-25"
                    disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BTable,
  BModal,
  BTooltip
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { onMounted, onUnmounted } from '@vue/composition-api'

import useIndex from './useIndex'
import scannerStoreModule from './scannerStoreModule'
import ShowModal from './ShowModal'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BTable,
    BModal,
    BTooltip,
    ShowModal,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SCANNER_STORE_MODULE_NAME = 'scanner'

    // Register module
    if (!store.hasModule(SCANNER_STORE_MODULE_NAME)) store.registerModule(SCANNER_STORE_MODULE_NAME, scannerStoreModule)

    onMounted(() => {
    //   fetchStart();
    })
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCANNER_STORE_MODULE_NAME)) store.unregisterModule(SCANNER_STORE_MODULE_NAME)
    })

    const {
      productList,
      fetchStart,
      scannerData,
      searchQuery,
      searchData,
      totalPrice,
      resetPrice,
      refProductsTable,
      tableColumns,
      fetchProducts,
      confirmDelete,
      refetchData,
      testList,
      refNoticeShowModal,
      refShowNoticeComponent,
      showModal,
      showModalShown,
      shownId,
      sortBy,
      isSortDirDesc,
      resetForm,
      searchQuery2,
      checkDatas,
      finishPrice,
      payListSend,
    } = useIndex(SCANNER_STORE_MODULE_NAME)

    return {
      productList,
      fetchStart,
      scannerData : JSON.parse(localStorage.getItem('delivreyLists')),
      searchQuery,
      searchData,
      totalPrice,
      resetPrice,
      refProductsTable,
      tableColumns,
      fetchProducts,
      confirmDelete,
      refetchData,
      testList,
      refNoticeShowModal,
      refShowNoticeComponent,
      showModal,
      showModalShown,
      shownId,
      sortBy,
      isSortDirDesc,
      resetForm,
      searchQuery2,
      checkDatas,
      finishPrice,
      payListSend,
    }
  },
}
</script>

<style lang="scss">
.navbar-container{display:none !important;}
</style>