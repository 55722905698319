import { ref } from '@vue/composition-api'
import store from '@/store'
// Sweet Alert
import Swal from 'sweetalert2'
import useJwt from '@/auth/jwt/useJwt'
// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useIndex(STORE_MODULE_NAME) {

  const toast = useToast();
  const refNoticeShowModal = ref(null);
  const refShowNoticeComponent = ref(null);

  const shownId = ref(0);


  const productList = ref([]);
  const scannerData = ref({
    id :'',
    name:'',
    cart:'',
    phone:'',
    roadAddress:'',
    addAddress:'',
    delivaryDate:'',
    delivaryTime:''
  });

  const searchQuery = ref('');

  const searchQuery2 = ref('');
  
  const totalPrice = ref(0);



  const fetchStart = () => {
    store.commit('showBlockUI', store.state.common.state);
    let datas ={
      accessToken : useJwt.getToken()
    }
    let accessToken = useJwt.getToken()
    // console.log('accessToken', accessToken)
    store
      .dispatch(`${STORE_MODULE_NAME}/fetchStart` , datas)
      .then(response => {
        // console.log('response.data', response.data[String("1")])
        const data = response.data
        productList.value = data;
        // console.log(' productList.value',  productList.value)
        store.commit('hideBlockUI', store.state.common.state);
      })
      .catch((err) => {
        store.commit('hideBlockUI', store.state.common.state);
        console.log('err',err)
        toast(
          {
            component: ToastificationContent,
            props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '정보를 가져오던 중 오류가 발생 했습니다.', variant: 'danger' },
          },
          { position: 'top-center' },
        );
      });
  };
  
  const refProductsTable = ref(null);

  const tableColumns = [
    { key: 'id', label: 'No.', thStyle: { width: '10%' }, class: 'text-center' },
    { key: 'code', label: '상품 코드', thStyle: { width: '20%' } },
    { key: 'name', label: '상품명', thStyle: { width: '30%' } },
    { key: 'amount', label: '개수', thStyle: { width: '10%' } },
    { key: 'price', label: '가격', thStyle: { width: '10%' } },
    { key: 'discount', label: '할인', thStyle: { width: '10%' } },
    { key: 'action', label: '삭제', thStyle: { width: '20%' } },
  ];

  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const testList = ref([])
  let basketList = ref([])
  let sendList =[]


  //테이블에 들어가는 메서드 다시 실행
  const refetchData = () => {

    refProductsTable.value.refresh();
  };

  //테이블에 들어가는 메서드
  const fetchProducts = (ctx, callback) => {
    const datas = {
      searchQuery: searchQuery.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    };
    // store
    //   .dispatch(`${STORE_MODULE_NAME}/fetchProducts`, datas)
    //   .then(response => {
    //     const data = response.data.result.data;
    //     callback(data);
    //   })
    //   .catch(() => {
    //     toast(
    //       {
    //         component: ToastificationContent,
    //         props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '리스트를 불러오던 중 오류가 발생했습니다.', variant: 'danger' },
    //       },
    //       { position: 'top-center' },
    //     );
    //     callback([]);
    //   });
  };

 

  //상품을 스캔해주세요 메서드
  const searchData = () => {
    let datas ={
      accessToken : useJwt.getToken()
    }
    datas.cart = searchQuery.value
    // console.log('searchQuery', { cart : searchQuery.value , accessToken : useJwt.getToken() })
    store
      .dispatch(`${STORE_MODULE_NAME}/fetchProductSingle`, datas)
      .then(response => {
        const data = response.data
        // console.log('data' , response.data)
        scannerData.value = data;
        localStorage.setItem('delivreyLists', JSON.stringify(response.data));
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '정보를 가져오던 중 오류가 발생 했습니다.', variant: 'danger' },
          },
          { position: 'top-center' },
        );
      });
  };

  const confirmDelete = id => {
    Swal.fire({
      title: '상품 삭제',
      text: '해당 상품을 삭제하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '삭제',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-outline-primary ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        deleteProduct(id);
      }
    })
  }

  const deleteProduct = function (id) {
    console.log('id', id)
    toast(
      {
        component: ToastificationContent,
        props: { title: 'Success', icon: 'BellIcon', text: '상품이 삭제 되었습니다.', variant: 'success' },
      },
      { position: 'top-center' },
    );
    refetchData();
    // store
    //   .dispatch(`${STORE_MODULE_NAME}/deleteProduct`, { id })
    //   .then(response => {
    //     const result = response.data.result;
    //     if (result) {
    //       if (result.code == 'success') {
    //       }
    //     }
    //   })
    //   .catch(() => {
    //     toast(
    //       {
    //         component: ToastificationContent,
    //         props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '상품 삭제 중 오류가 발생했습니다.', variant: 'danger' },
    //       },
    //       { position: 'top-center' },
    //     );
    //   });
  };

  // const showModal = id => {
  //   refNoticeShowModal.value.show();
  //   shownId.value = id;
    
  // };

  const finishPrice = ref(0)

  const showModal = function() {

    const productDatas = sendList.reduce((acc, obj) => {
      const existingObj = acc.find(item => item.barCode === obj.barCode && item.addAble ===true && obj.addAble === true);
      if (existingObj) {
        existingObj.amount += obj.amount;
        existingObj.totalPrice += obj.totalPrice;
        existingObj.discount += obj.discount;
        // existingObj.id = existingObj.id;

      } else {
        acc.push(obj);
      }
      return acc;
    }, []);
    // console.log('scannerData', scannerData.value)
    if(scannerData.value.cart != '' && scannerData.value.cart != null && scannerData.value.id != '' && scannerData.value.id != null){
      if(productDatas.length >0){
        store
        .dispatch(`${STORE_MODULE_NAME}/fetchModalList`, { 
          products : productDatas,
          carCode : scannerData.value.cart,
          customerId : scannerData.value.id,
          accessToken : useJwt.getToken()
         })
          .then(response => {
            const result = response.data;
            if (result) {
              refNoticeShowModal.value.show();
              // console.log('result', response.data)
              localStorage.setItem('productList', JSON.stringify(response.data));
              // refShowNoticeComponent.value.testList.value = response.data.products
              console.log(`localStorage.getItem('productList')` , JSON.parse(localStorage.getItem('productList')).products)
              finishPrice.value = JSON.parse(localStorage.getItem('productList')).totalPrice
              refetchData();
            }
          })
          .catch(() => {
            toast(
              {
                component: ToastificationContent,
                props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '상품 멀티 할인 적용 중 문제 발생.', variant: 'danger' },
              },
              { position: 'top-center' },
            );
          });

      }else{
        toast(
          {
            component: ToastificationContent,
            props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '상품이 등록되지 않았습니다.', variant: 'danger' },
          },
          { position: 'top-center' },
        );
      }

    }else{
      toast(
        {
          component: ToastificationContent,
          props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '카트 정보 미등록.', variant: 'danger' },
        },
        { position: 'top-center' },
      );
    }
  };


  // const showModal = (ctx, callback) => {


  //   store
  //     .dispatch(`${STORE_MODULE_NAME}/fetchModalList`, {
  //       products : sendList
  //     })
  //     .then(response => {
  //       refNoticeShowModal.value.show();
  //       console.log('result', response.data)
  //       localStorage.setItem('productList', response.data);

  //       const data = response.data;
  //       callback(data);
  //     })
  //     .catch((err) => {
  //       console.log('err',err)
  //       toast(
  //         {
  //           component: ToastificationContent,
  //           props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '리스트를 불러오던 중 오류가 발생했습니다.', variant: 'danger' },
  //         },
  //         { position: 'top-center' },
  //       );
  //       callback([]);
  //     });

  // };


  const listUpdate = ()=>{
    const imsiList2 = JSON.parse(JSON.stringify(basketList.value));
    // console.log('basketList1',basketList.value)
    searchQuery2.value = ''
    // searchQuery2.value.refresh()
    const imsiList = imsiList2.reduce((acc, obj) => {
      const existingObj = acc.find(item => item.code === obj.code && item.addAble ===true && obj.addAble === true);
      if (existingObj) {
        existingObj.amount += obj.amount;
        existingObj.price += obj.price;
        existingObj.discount += obj.discount;
        existingObj.id = existingObj.id;

      } else {
        acc.push(obj);
      }
      return acc;
    }, []);

    testList.value = imsiList
    // console.log('basketList2',basketList.value)
    // console.log('testList',testList.value)
    // testList = mergedArray
    
    refetchData()
  }

  const checkDatas = () => {
    const searchQuery3 = JSON.parse(JSON.stringify(searchQuery2.value));
    if(searchQuery3.includes("DISCOUNT")){
      if(basketList.value.length>0){
        let discount =  searchQuery3.replace("DISCOUNT100000","").split("")
        // console.log('discount',discount)
        // console.log('discount2',(Number(`0.${discount[0]}${discount[1]}`)))

        // let discountPrice =  basketList.value[basketList.value.length-1].price - Math.ceil((basketList.value[basketList.value.length-1].price)*(Number(`0.${discount[0]}${discount[1]}`)))
        let discountPrice =  Math.ceil((basketList.value[basketList.value.length-1].price)*(Number(`0.${discount[0]}${discount[1]}`)))

        basketList.value[basketList.value.length-1].discount =  basketList.value[basketList.value.length-1].discount+discountPrice

        sendList[sendList.length-1].discount = sendList[sendList.length-1].discount + discountPrice
        
        totalPrice.value = totalPrice.value -discountPrice
        listUpdate()
      }
    }else{
        if(searchQuery3.length ==12){
          if(productList.value[String(searchQuery3.slice(0,7))] && 'EA' ==(productList.value[String(searchQuery3.slice(0,7))].unit).toUpperCase()){
            basketList.value.push({
              id: testList.value.length+1,
              code: productList.value[String(searchQuery3.slice(0,7))].barCode ,
              name : productList.value[String(searchQuery3.slice(0,7))].name ,
              amount : productList.value[String(searchQuery3.slice(0,7))].amount ,
              price : Number(searchQuery3.slice(7,12)) ,
              discount : 0,
              addAble : true
            })
            sendList.push({
              id: testList.value.length+1,
              barCode : productList.value[String(searchQuery3.slice(0,7))].barCode ,
              amount : productList.value[String(searchQuery3.slice(0,7))].amount ,
              totalPrice : Number(searchQuery3.slice(7,12)) ,
              discount : 0,
              addAble : true
            })
            totalPrice.value = totalPrice.value +Number(searchQuery3.slice(7,12)) 
            listUpdate()
              
            }else{
            basketList.value.push({
              id: testList.value.length+1,
              code: productList.value[String(searchQuery3.slice(0,7))].barCode ,
              name : productList.value[String(searchQuery3.slice(0,7))].name ,
              amount : productList.value[String(searchQuery3.slice(0,7))].amount ,
              price : productList.value[String(searchQuery3.slice(0,7))].totalPrice ,
              discount : 0,
              addAble : true
            })
            sendList.push({
              id: testList.value.length+1,
              barCode : productList.value[String(searchQuery3.slice(0,7))].barCode ,
              amount : productList.value[String(searchQuery3.slice(0,7))].amount ,
              totalPrice : productList.value[String(searchQuery3.slice(0,7))].totalPrice ,
              discount : 0,
              addAble : true
            })
            totalPrice.value = totalPrice.value + productList.value[String(searchQuery3.slice(0,7))].totalPrice
            listUpdate()
          }
          
        }else{
          if(searchQuery3.slice(0,2).includes('50') && searchQuery3.length >19 && searchQuery3.length != 21){
            let barcodes = searchQuery3.length == 20 ? searchQuery3.slice(0,7) : searchQuery3.slice(0,9)
            
            if( "KG" == ((productList.value[String(barcodes)].unit).toUpperCase())  || "G" == ((productList.value[String(barcodes)].unit).toUpperCase())){
              if(searchQuery3.length == 22){
                basketList.value.push({
                  id: testList.value.length+1,
                  code: productList.value[String(barcodes)].barCode ,
                  name : productList.value[String(barcodes)].name ,
                  amount :  Number(searchQuery3.slice(9,14)),
                  price :  Number(searchQuery3.slice(14,21)),
                  discount : 0,
                  addAble : false
                })
                sendList.push({
                  id: testList.value.length+1,
                  barCode : productList.value[String(barcodes)].barCode ,
                  amount : Number(searchQuery3.slice(9,14)) ,
                  totalPrice : Number(searchQuery3.slice(14,21)) ,
                  discount : 0,
                  addAble : false
                })
                totalPrice.value = totalPrice.value +  Number(searchQuery3.slice(14,21))
                listUpdate()
              }else{
                basketList.value.push({
                  id: testList.value.length+1,
                  code: productList.value[String(barcodes)].barCode ,
                  name : productList.value[String(barcodes)].name ,
                  amount :  Number(searchQuery3.slice(7,12)),
                  price :  Number(searchQuery3.slice(12,19)),
                  discount : 0,
                  addAble : false
                })
                sendList.push({
                  id: testList.value.length+1,
                  barCode : productList.value[String(barcodes)].barCode ,
                  amount : Number(searchQuery3.slice(7,12)) ,
                  totalPrice : Number(searchQuery3.slice(12,19)) ,
                  discount : 0,
                  addAble : false
                })

                totalPrice.value = totalPrice.value +  Number(searchQuery3.slice(12,19))
                listUpdate()
              }
            }else{
              basketList.value.push({
                id: testList.value.length+1,
                code: productList.value[String(barcodes)].barCode ,
                name : productList.value[String(barcodes)].name ,
                amount : productList.value[String(barcodes)].amount ,
                price : productList.value[String(barcodes)].totalPrice ,
                discount : 0,
                addAble : false
              })
              sendList.push({
                id: testList.value.length+1,
                barCode : productList.value[String(barcodes)].barCode ,
                amount : productList.value[String(barcodes)].amount ,
                totalPrice : productList.value[String(barcodes)].totalPrice ,
                discount : 0,
                addAble : false
              })
              totalPrice.value = totalPrice.value +  productList.value[String(barcodes)].totalPrice
              listUpdate()
            }
          }else{
            basketList.value.push({
              id: testList.value.length+1,
              code: productList.value[String(searchQuery3)].barCode ,
              name : productList.value[String(searchQuery3)].name ,
              amount : productList.value[String(searchQuery3)].amount ,
              price : productList.value[String(searchQuery3)].totalPrice ,
              discount : 0,
              addAble : true
            })
            sendList.push({
              id: testList.value.length+1,
              barCode : productList.value[String(searchQuery3)].barCode ,
              amount : productList.value[String(searchQuery3)].amount ,
              totalPrice : productList.value[String(searchQuery3)].totalPrice ,
              discount : 0,
              addAble : true
            })
            totalPrice.value = totalPrice.value + productList.value[String(searchQuery3)].totalPrice
            listUpdate()
          }
        }

    }
    // searchQuery2.value = ""

  };

  const resetPrice = () => {
    sendList =[]
    basketList.value =[]
    totalPrice.value = 0
    testList.value =[]
    refetchData()
  };

  const testFunctions = () => {
    const array = [
      { id: 1, value: 10 , d:203  , flexs : "포켓"},
      { id: 2, value: 20  , d:2034 , flexs : "포켓"},
      { id: 1, value: 30, d: 203 , flexs : "포켓" },
      { id: 3, value: 40 , d:203 , flexs : "포켓"},
      { id: 2, value: 50 , d:203 , flexs : "포켓"}
    ];
    
    const mergedArray = array.reduce((acc, obj) => {
      const existingObj = acc.find(item => item.id === obj.id);
      if (existingObj) {
        existingObj.value += obj.value;
        existingObj.d += obj.d;
      } else {
        acc.push(obj);
      }
      return acc;
    }, []);
    
    console.log(mergedArray);
  };


  const showModalShown = () => {
    console.log('showModalShown ok')
    refShowNoticeComponent.value.products = JSON.parse(localStorage.getItem('productList')).products
    // refShowNoticeComponent.value.refProductsTable.value.refresh();
  };


  const payListSend = () => {
    console.log('good bye ok')
    
    let sendObj ={
      accessToken : useJwt.getToken(),
      cartCode : searchQuery.value,
      totalPrice : JSON.parse(localStorage.getItem('productList')).totalPrice,
      products : JSON.parse(localStorage.getItem('productList')).products,
      customerId : scannerData.value.id
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/fetchProducts`, sendObj)
      .then(response => {
        const data = response.data
        localStorage.removeItem('productList')
        localStorage.removeItem('delivreyLists')
        sendList = []
        basketList.value = []
        totalPrice.value = 0
        testList.value = []
        scannerData.value ={
          id :'',
          name:'',
          cart:'',
          phone:'',
          roadAddress:'',
          addAddress:'',
          delivaryDate:'',
          delivaryTime:''
        };
        refetchData()
        refNoticeShowModal.value.hide();
        console.log('data' , data)

        // scannerData.value = data;
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '정보를 가져오던 중 오류가 발생 했습니다.', variant: 'danger' },
          },
          { position: 'top-center' },
        );
      });

  };

  const resetForm = () => {
    refNoticeShowModal.value.hide();
    localStorage.removeItem('productList')
    // scannerData.value = {
    //   id:'',
    //   name:'',
    //   cart:'',
    //   phone:'',
    //   roadAddress:'',
    //   addAddress:'',
    //   delivaryDate:'',
    //   delivaryTime:''
    // };
    // searchQuery.value = '';
    // refetchData();
  };

  const goPopstore =() =>{
    window.open("/popopo" , "_black" , "width=480, height=720");
  }
  
  return {
    productList,
    fetchStart,
    scannerData,
    searchQuery,
    searchData,
    totalPrice,
    resetPrice,
    refProductsTable,
    tableColumns,
    fetchProducts,
    confirmDelete,
    refetchData,
    testList,
    refNoticeShowModal,
    refShowNoticeComponent,
    showModal,
    showModalShown,
    shownId,
    sortBy,
    isSortDirDesc,
    resetForm,
    searchQuery2,
    checkDatas,
    testFunctions,
    basketList,
    listUpdate,
    finishPrice,
    payListSend,
    goPopstore,
  }
}
