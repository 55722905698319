import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchStart(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // console.log('e')
        axios
          .post('/scannerData', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      });
    },
    fetchModalList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        console.log('e2',queryParams)
        

        axios
          .post('/totalDiscountFunction', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      });
    },
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/scannerComplete2', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      });
    },
    fetchProductSingle(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/scannerCustomer' , queryParams)
          .then(response => resolve(response))
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteProduct(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/scanner/${queryParams.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
