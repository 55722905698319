import { ref } from '@vue/composition-api';
import store from '@/store'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useShowModal(STORE_MODULE_NAME, props, context) {
  const toast = useToast();

  const refProductsTable = ref(null);

  const tableColumns = [
    { key: 'barCode', label: '상품 코드', thStyle: { width: '20%' } },
    { key: 'name', label: '상품명', thStyle: { width: '30%' } },
    { key: 'amount', label: '개수', thStyle: { width: '10%' } },
    { key: 'totalPrice', label: '가격', thStyle: { width: '10%' } },
    { key: 'discount', label: '할인', thStyle: { width: '10%' } },
    { key: 'id', label: '지불액', thStyle: { width: '10%' }, class: 'text-center' },
  ];

  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const products =ref([]);

  const testList = JSON.parse(localStorage.getItem('productList')).products2
  // const testList =ref([]);

  const fetchModalList = () => {
    refProductsTable.value.refresh();
    // const myValue = localStorage.getItem('myKey');
    console.log('?')
    // store
    //   .dispatch(`${STORE_MODULE_NAME}/fetchModalList`, {
    //     sortBy: sortBy.value,
    //     sortDesc: isSortDirDesc.value,
    //     products : products.value
    //   })
    //   .then(response => {
    //     console.log('result', response.data)
    //     const data = response.data.result.data;
    //     callback(data);
    //   })
    //   .catch(() => {
    //     toast(
    //       {
    //         component: ToastificationContent,
    //         props: { title: 'Warning', icon: 'AlertTriangleIcon', text: '리스트를 불러오던 중 오류가 발생했습니다.', variant: 'danger' },
    //       },
    //       { position: 'top-center' },
    //     );
    //     callback([]);
    //   });
  };

  
  return {
    refProductsTable,
    tableColumns,
    sortBy,
    isSortDirDesc,
    testList,
    fetchModalList,
    products,
  };
}
